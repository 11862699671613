import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/templates/Docs/index.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "quickstart-guide",
      "style": {
        "position": "relative"
      }
    }}>{`Quickstart Guide`}<a parentName="h1" {...{
        "href": "#quickstart-guide",
        "aria-label": "quickstart guide permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h1>
    <p>{`This quickstart guide is here to help you integrate with and start
benefiting from TaleFin's system as quickly and easily as possible.`}</p>
    <p>{`In this guide, we will be connecting a user with their nominated bank account,
extracting and analysing all of the transactions from the last year in that
account, finally viewing the result in a nicely formatted PDF or HTML document.`}</p>
    <h2 {...{
      "id": "access",
      "style": {
        "position": "relative"
      }
    }}>{`Access`}<a parentName="h2" {...{
        "href": "#access",
        "aria-label": "access permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`To gain access, you must first create an account by using the demo (top right hand corner of this site). All the information you need will
be sent to you in an email. Once that is done, you can use the iFrame and access the dashboard to view the analyses.`}</p>
    <p>{`You will be provided with a vendor label; basically a small identifier that lets us know the application belongs to you. This label will
be used in some API requests as a way of quickly and positively identifying your organisation.`}</p>
    <h2 {...{
      "id": "basic-user-flow",
      "style": {
        "position": "relative"
      }
    }}>{`Basic User Flow`}<a parentName="h2" {...{
        "href": "#basic-user-flow",
        "aria-label": "basic user flow permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`There are few simple steps required to connect an end-user to the TaleFin system and extract an analysis of their bank account information. For this
example, we're going to perform the simplist application possible by using the iFrame and Dashboard. For more information on how to deeplink into
the iFrame or how to integrate using the API, feel free to reach out.`}</p>
    <ul>
      <li parentName="ul">{`Create a unique iFrame URL for the customer`}</li>
      <li parentName="ul">{`Deliver the url to the customer; probably just pasting it into an Incognito Window for this example`}</li>
      <li parentName="ul">{`Once the user has completed the application, log into the Dashboard and view the application`}</li>
    </ul>
    <p>{`For testing, we have Bank of TaleFin. You can use it to test as much as you like and it can even simulate all kinds of successes and errors.`}</p>
    <h2 {...{
      "id": "creating-a-unique-url",
      "style": {
        "position": "relative"
      }
    }}>{`Creating a unique URL`}<a parentName="h2" {...{
        "href": "#creating-a-unique-url",
        "aria-label": "creating a unique url permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`There are only two parameters required to create a unique iFrame URL. One is your vendor label, which was created when you used the demo
just before and was emailed to you. And the other is a unique customer identifier that is only used for that one application. A good choice
would be to use a UUID or some kind of convention that rotates and isn't easily duplicated.
If you want to track the customer in your backend, then it's important to store the Vendor Specific Id somewhere stateful. In some
use cases, however, you may not have that requirement; in which case using any random identifier that can't be duplicated will suffice.`}</p>
    <p>{`There are plenty of options for the iFrame that allow you to control the behaviour and branding for an optimal customer experience.
Capabilities include deep linking to specific steps, pre-filling information, as well as customizing the page font and colours to match your brand.
You can find more information by reading the `}<a parentName="p" {...{
        "href": "/docs/v1/iframe-guide"
      }}>{`iFrame Guide`}</a>{`.`}</p>
    <p>{`Here's an example URL:`}</p>
    <deckgo-highlight-code {...{
      "language": "shell"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`https://banks.talefin.com/i/{VENDOR_LABEL}/{VENDOR_SPECIFIC_ID}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Or in the form of an `}<inlineCode parentName="p">{`iframe`}</inlineCode>{` HTML tag:`}</p>
    <deckgo-highlight-code {...{
      "language": "html"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<iframe src="https://banks.talefin.com/i/{VENDOR_LABEL}/{VENDOR_SPECIFIC_ID}/" />`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Again, this is the simplest possible example; there are plenty of arguments and parameters you can use to prefill or deep link into the iFrame.`}</p>
    <p>{`If you plan to wrap the iFrame in your own parent window,
there are a many events you can listen to and utilize to
improve your customer's experience.
These events are beyond the scope of this quickstart guide however,
so head over to the `}<a parentName="p" {...{
        "href": "/docs/v1/iframe-guide"
      }}>{`Iframe Guide`}</a>{` when you'd like to learn more.`}</p>
    <p>{`And, that's it!
Once your customer has completed the application, their data will appear in the dashboard.`}</p>
    <h2 {...{
      "id": "retrieving-the-data",
      "style": {
        "position": "relative"
      }
    }}>{`Retrieving the data`}<a parentName="h2" {...{
        "href": "#retrieving-the-data",
        "aria-label": "retrieving the data permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`Heading over to the `}<a parentName="p" {...{
        "href": "/dashboard/"
      }}>{`dashboard`}</a>{` you can view the application in the `}<inlineCode parentName="p">{`Application`}</inlineCode>{` or `}<inlineCode parentName="p">{`Analysis`}</inlineCode>{` list. Opening the look up
will reveal all the artifacts generated by the analysis process. Up the top is the summary and further down where
the accounts list is, are the analyses to the right.`}</p>
    <p>{`In the real world you would have the TaleFin webhook the results to your backend so you can react to the
events by downloading the parts you're interested in programatically.`}</p>
    <p>{`An easy way to test this is to create a url with
`}<a parentName="p" {...{
        "href": "https://webhook.site"
      }}>{`webhook.site`}</a>{` to test with. You can,
however, change or remove these at any time and replace
with your own webhooks, either through the API or in the
dashboard.`}</p>
    <p>{`These webhooks will notify you when each part of the data
for the account has been analysed and ready for viewing.
To make it faster and more responsive, analysis is done
in such a way that we send the data in segments as it’s
ready for you to view. However, for simplicity’s sake, we
shall wait until all analysis is completed. When it’s done
you will receive a webhook event called `}<inlineCode parentName="p">{`application.report_ready`}</inlineCode>{`:`}</p>
    <deckgo-highlight-code {...{
      "language": "json"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`{
  "event": "application.report_ready",
  "payload": {
    "id": 100000,
    "vendor_specific_id": "123456789",
    "vendor_label": "test",
    "timestamp": "2020-09-10T08:53:02.229592+10:00",
    "crawler": [
      {
        "profile": 10000,
        "uuid": "ba8f4a5e-be0b-4bcd-954a-483d86628e90",
        "application": 100000,
        "bank_id": 130,
        "timestamp": "2020-09-10T08:53:04.039916+10:00",
        "status": "COMPLETED"
      }
    ],
    "full_name": "Donald Duck",
    "email": "test@test.com",
    "mobile": "04000000",
    "finalised": true,
    "analysed": true
  }
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Using this information, we can view the fruits of our
labour, in HTML:`}</p>
    <deckgo-highlight-code {...{
      "language": "shell"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`https://banks.talefin.com/api/applications/{application_id}/summary/html`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`in JSON:`}</p>
    <deckgo-highlight-code {...{
      "language": "shell"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`https://banks.talefin.com/api/applications/{application_id}/summary`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`or PDF:`}</p>
    <deckgo-highlight-code {...{
      "language": "shell"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`https://banks.talefin.com/api/applications/{application_id}/summary/pdf`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`There are of course many more events that get fired all organised into groups that match the data. But this
was just an example of the simplest possible end-to-end test run.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      